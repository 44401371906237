exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colors-js": () => import("./../../../src/pages/colors.js" /* webpackChunkName: "component---src-pages-colors-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mixmaster-js": () => import("./../../../src/pages/mixmaster.js" /* webpackChunkName: "component---src-pages-mixmaster-js" */),
  "component---src-pages-snapscape-js": () => import("./../../../src/pages/snapscape.js" /* webpackChunkName: "component---src-pages-snapscape-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

